import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ImageHomeBgSpotSource from "../images/home-bg-spot-source.png"
import ImageHomeBack from "../images/home-shoulder-and-pelvic-girdle-inflammation.png"
import ImageLinesAndCircles from "../images/home-line-circles.png"
import Image2ndMostCommon from "../images/home-second-most-common-mobile.png"
import ImageNoSingularVertical from "../images/home-no-singular-vertical-line.png"
import ImageNoSingularHorizontal from "../images/home-no-singular-horizontal-line.png"
import ImageNoSingular1point from "../images/home-no-singular-1-point.png"
import ImageNoSingular2points from "../images/home-no-singular-2-points.png"
import ImageHomeBgCommonFeatures from "../images/home-bg-common-features.png"
import ImageHomeShoulder from "../images/home-shoulder-inflammation.png"
import ImageLineSmall from "../images/line-small.svg"
import ImageLineLong from "../images/line-long.svg"
import ImageIconLimitedRange from "../images/icon-limited-range-of-motion.svg"
import ImageIconUnilateralShoulder from "../images/icon-shoulder-bursitis-icon.svg"
import ImageIconLowGrade from "../images/icon-arrow-and-thermometer.svg"
import ImageIconPhysicalActivity from "../images/icon-physical-activity.svg"
import ImageIconIsGreaterThan from "../images/icon-is-greater-than.png"
import ImageIconCalloutArrow from "../images/icon-callout-brown-arrow.svg"

const IndexPage = () => (
  <Layout pageid="home">
    <Seo title="Understanding PMR | PMR and IL-6 HCP Website" description="Learn more about the prevalence and diagnosis of PMR." ogDesc="Learn more about the prevalence and diagnosis of PMR." lang="en-US" meta=""/>
    <div className="content-container">

      <div id="home-spot-source-container">
        <img src={ImageHomeBgSpotSource} alt="" id="home-bg-spot-source" />
        <img src={ImageHomeBack} alt="shoulder and pelvic girdle inflammation" id="home-image-body" />
        <div id="home-spot-source-copy-container">
          <img src={ImageLineSmall} alt="" className="mb-1 md_mb-1 lg_mb-3" />
          <h1 id="home-headline-spot-source" className="section-headline">UNDERSTANDING PMR</h1>
          <img src={ImageLineSmall} alt="" className="mb-1 md_mb-2 mt-1 md_mt-1 lg_mt-3" />
          <p className="mb-2 md_mb-2">Key hallmarks associated with polymyalgia rheumatica (PMR), such as painful inflammation, can be attributed, in part, to certain immune cells and cytokines, such as interleukin-6 (IL-6).<sup>1,2</sup></p>
          <p className="mb-2 md_mb-2">Current guidelines establish glucocorticoids (GCs) as the standard of care; however, challenges remain such as time on therapy, relapses, and risks with the increase of GC-related toxicities.<sup>3-6</sup></p>
          <p className="mb-2 md_mb-2">Many patients may need a PMR treatment strategy that minimizes the use of GCs.<sup>5</sup></p>
        </div>
      </div>

      <div id="home-lines-and-circles-container"><img src={ImageLinesAndCircles} alt="" /></div>
      <div id="home-2nd-most-container" className="mb-9 md_mb-9 pb-5 md_pb-4">
        <h2 className="section-headline">
          PMR is the second-most common inflammatory rheumatic disease after rheumatoid arthritis (RA)<sup>4</sup><br/>
          <img src={ImageLineLong} alt="" className="show-for-medium mb-2 md_mb-2 mt-2 md_mt-2"/>
          <img src={ImageLineSmall} alt="" className="show-for-small-only mb-2 md_mb-2 mt-2 md_mt-2"/>
        </h2>

        <div className="row mb-2 md_mb-2" id="home-is-greater-than-container">
          <div className="small-12 medium-12 large-5 section-subhead text-center align-self-middle">The residual lifetime risk of developing PMR in both men and women<sup>7</sup></div>
          <div className="small-12 medium-12 large-2 text-center align-self-middle image-icon-is-greater"><img src={ImageIconIsGreaterThan} alt=""/></div>
          <div className="small-12 medium-12 large-5 section-subhead text-center align-self-middle">The risk of developing rheumatoid arthritis, as well as other inflammatory rheumatic diseases in individuals aged 60 years or older<sup>7</sup></div>
        </div>

        <p>In the United States, the lifetime incidence rate of developing PMR is ~2%, regardless of sex.<sup>8</sup></p>
        <img src={Image2ndMostCommon} alt="second-most common" id="home-image-2nd-most-common" />
      </div>

      <div id="home-common-features-container" className="mb-6 md_mb-6 pb-6 md_pb-6">
        <div className="home-common-featues-content-container">
          <h2 className="section-headline">Common features of PMR may support alternative diagnoses<sup>9</sup></h2>
          <p><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
          <p>
            Careful consideration of conditions that may mimic PMR are important.<sup>10</sup>
            <ul><li>PMR is typically characterized by symmetrical pain, and morning stiffness in the neck, shoulders, and pelvic girdle<sup>4</sup></li></ul>
          </p>
          <div className="row home-common-features-icons">
            <div className="columns small-12 medium-12 large-6 mb-2 md_mb-2 text-center">
              <p className="home-cfi-icon-container"><img src={ImageIconLimitedRange} alt="limited range of motion icon" /></p>
              <div className="home-cfi-gray-box">
                <p className="section-subhead pb-1 md_pb-1">Limited range of motion</p>
                <p>Limited range of motion in shoulders occurs in patients with PMR<sup>11</sup></p>
              </div>
            </div>
            <div className="columns small-12 medium-12 large-6 mb-2 md_mb-2 text-center">
              <p className="home-cfi-icon-container"><img src={ImageIconUnilateralShoulder} alt="shoulder bursitis icon" /></p>
              <div className="home-cfi-gray-box">
                <p className="section-subhead pb-1 md_pb-1">Unilateral shoulder bursitis</p>
                <p>Unilateral shoulder bursitis occurs in up to 96% of patients with PMR<sup>12</sup></p>
              </div>
            </div>
          </div>
          <div className="row home-common-features-icons">
            <div className="columns small-12 medium-12 large-6 mb-2 md_mb-2 text-center">
              <p className="home-cfi-icon-container"><img src={ImageIconLowGrade} alt="arrow and thermometer icon" /></p>
              <div className="home-cfi-gray-box">
                <p className="section-subhead pb-1 md_pb-1">Low-grade fever, asthenia, anorexia, and weight loss</p>
                <p>Manifestations can include low-grade fever, asthenia, anorexia, and weight loss, occurring in 40% to 50% of patients with PMR<sup>1</sup></p>
              </div>
            </div>
            <div className="columns small-12 medium-12 large-6 mb-2 md_mb-2 text-center">
              <p className="home-cfi-icon-container"><img src={ImageIconPhysicalActivity} alt="physical activity icon" /></p>
              <div className="home-cfi-gray-box">
                <p className="section-subhead pb-1 md_pb-1">Can improve with physical activity</p>
                <p>PMR pain can be distinguished from mechanical because pain from PMR can improve with physical activity<sup>4,10</sup></p>
              </div>
            </div>
          </div>

        </div>
        <div className="callout-orange align-right" style={{ marginLeft: '7%' }}>
          <p>To rule out other medical conditions, it is advisable to perform a complete physical investigation and basic lab tests: full blood count (FBC), urea and electrolytes (U+E), liver function tests (LFTs), calcium, creatinine kinase (CK), thyroid stimulating hormone (TSH), and immunoglobulins.<sup>10</sup></p>
        </div>
        <img src={ImageHomeBgCommonFeatures} alt="" id="home-image-common-features-bg-bottom" className="show-for-large" />
      </div>

      <div id="home-no-singular">
        <h2 className="section-headline">There are no diagnostic tests specific to PMR<sup>9</sup></h2>
        <p className="show-for-small-only"><img src={ImageLineSmall} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
        <p className="show-for-medium"><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
        <p className="section-subhead mb-2 md_mb-2">All patients suspected of having PMR are recommended to complete a comprehensive physical examination with attention to neurologic, vascular, and musculoskeletal areas<sup>9</sup></p>
        <p className="mb-6 md_mb-6">The American College of Rheumatology and the European Alliance of Associations for Rheumatology propose classification criteria for diagnosing PMR as people ≥50 years with bilateral shoulder aching and abnormal C-reactive protein concentrations or erythrocyte sedimentation rate (ESR), plus at least 4 points (without ultrasound) or 5 points or more (with ultrasound) from<sup>1</sup>:</p>
        <div className="row lg_mb-5">
          <div className="columns small-12 medium-12 large-5">
            <div className="home-no-singular-point-container mb-6">
              <img src={ImageNoSingular1point} alt="number one icon" id="home-image-ns-1pt" />
              <ul>
                <li>Hip pain or restricted range of motion</li>
                <li>Absence of other joint involvement</li>
                <li>Ultrasound findings of bilateral shoulder abnormalities (subdeltoid bursitis, biceps tenosynovitis, or glenohumeral synovitis) or abnormalities in 1 shoulder and hip (hip synovitis, trochanteric bursitis)</li>
                <li>If ultrasound is available, both shoulders with subdeltoid bursitis, biceps tenosynovitis, or glenohumeral synovitis</li>
              </ul>
            </div>
          </div>
          <div className="columns small-12 medium-12 large-2 text-center">
            <img src={ImageNoSingularHorizontal} alt="" className="show-for-small hide-for-large mb-6"/>
            <img src={ImageNoSingularVertical} alt="" className="show-for-large" style={{ height: '100%' }}/>
          </div>
          <div className="columns small-12 medium-12 large-5">
            <div className="home-no-singular-point-container">
              <img src={ImageNoSingular2points} alt="number two icon" id="home-image-ns-2pts" />
              <ul>
                <li>Morning stiffness lasting &gt;45 minutes</li>
                <li>Absence of rheumatoid factor or anti-citrullinated protein antibodies</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="home-shoulder-callout-container" className="mb-20 md_mb-20 lg_mb-20">
        <img src={ImageHomeShoulder} alt="shoulder inflammation" id="home-image-shoudler" />
        <Link to="/il-6-and-pmr/" className="gen-brown-callout">
          <div id="callout-no-singular">
            <div className="row">
              <div className="columns small-12 medium-9 text-center">Learn about the impact of cytokines <span className="nowrap-desktop">on PMR</span></div>
              <div className="columns small-12 medium-3 text-center nowrap align-self-middle"><img src={ImageIconCalloutArrow} alt="" /></div>
            </div>
          </div>
        </Link>
      </div>

      <br clear="all"/>

      <div id="references" className="home">
        <div id="references-content">
          <p>
            <strong>References: 1.</strong> Gonzalez-Gay MA, Matteson EL, Castañeda S. Polymyalgia rheumatica. <em>Lancet.</em> 2017; 390:1700-1712.&nbsp;
            <strong>2.</strong> Roche NE, Fulbright JW, Wagner AD, et al. Correlation of interleukin-6 production and disease activity in polymyalgia rheumatica and giant cell arteritis. <em>Arthritis & Rheumatism.</em> 1993;36(9):1286-1294.&nbsp;
            <strong>3.</strong> Buttgereit F, Dejaco C, Matteson EL, Dasgupta B. Polymyalgia rheumatica and giant cell arteritis: a systematic review. <em>JAMA.</em> 2016;315(22):2442-2458.&nbsp;
            <strong>4.</strong> Acharya S, Musa R. Polymyalgia Rheumatica. <em>NCBI Bookshelf.</em> StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2022.&nbsp;
            <strong>5.</strong> Floris A, Piga M, Chessa E, et al. Long-term glucocorticoid treatment and high relapse rate remain unresolved issues in the real life management of polymyalgia rheumatica: a systematic literature review and meta-analysis. <em>Clinical Rheum.</em> 2022;41:19-31.&nbsp;
            <strong>6.</strong> Mazzantini M, Torre C, Miccoli M, et al. Adverse events during longterm low-dose glucocorticoid treatment of polymyalgia rheumatica: a retrospective study. <em>Journal Rheumatol.</em> 2012;39(3):552-557.&nbsp;
            <strong>7.</strong> Crowson CS, Matteson EL, Myasoedova E, et al. The lifetime risk of adult-onset rheumatoid arthritis and other inflammatory autoimmune rheumatic diseases. <em>Arthritis & Rheumatism.</em> 2011;63(3):633-639.&nbsp;
            <strong>8.</strong> Muller S, Hider S, Helliwell T, et al. The epidemiology of polymyalgia rheumatica in primary care: a research protocol. <em>BMC Musculoskeletal Disorders.</em> 2012;13:102.&nbsp;
            <strong>9.</strong> Kermani TA, Warrington KJ. Advances and challenges in the diagnosis and treatment of polymyalgia rheumatica. <em>Ther Adv Musculoskel Dis.</em> 2014;6(1):8-19.&nbsp;
            <strong>10.</strong> Mackie SL. Polymyalgia rheumatica: pathogenesis and management. <em>Clinical Medicine.</em> 2013;13(4):398-400.&nbsp;
            <strong>11.</strong> Dasgupta B, Cimmino MA, Kremers HM, et al. 2012 Provisional Classification Criteria for Polymyalgia Rheumatica. <em>Arthritis & Rheumatism.</em> 2012;64(4):943-954.&nbsp;
            <strong>12.</strong> Ruta S, Rosa J, Navarta, et al. Ultrasound assessment of new onset bilateral painful shoulder in patients with polymyalgia rheumatica and rheumatoid arthritis. <em>Clin Rheumatol.</em> 2012;31:1383-1387.
          </p>
        </div>
      </div>

    </div>
  </Layout>
)

export default IndexPage
